import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import { Box, useTheme, Button, useMediaQuery } from "@mui/material";
import { useGetApiAccountBkashQuery, generalApi } from "state/api";
import Header from "components/Header";
import { NavLink } from "react-router-dom";
import { FiDollarSign } from "react-icons/fi";
import { DataGrid } from "@mui/x-data-grid";
import { IoFilter } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
// import { merchantTableColumns } from "utilities/CommonUtility";
import DataGridCustomToolbarForMerchants from "components/DataGridCustomToolbarForMerchants";
import Edit from "../bkash/Edit"
import Add from "../bkash/Add";
import Swal from 'sweetalert2';
import Sidebar from "components/Sidebar";
import Navbar from "components/Navbar";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { FaRegEye } from "react-icons/fa";
const Moneyconvertor = () => {
  const theme = useTheme();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const navigate = useNavigate();
const [find_agent,setfind_agent]=useState("");

  useEffect(() => {
    if (authUser === null || authUser.role === "merchant" || authUser.role === "subadmin") {
      navigate('/login');
    }
  }, [authUser])

  const { data, isLoading, refetch } = useGetApiAccountBkashQuery();
  
  
  useEffect(() => {
    if (!isAdding && !isEditing) {
      refetch();
    }
  }, [isAdding, isEditing]);
// --------------------agent data
const [pending_agent,setpending_agent]=useState([]);
const [count_num,setcount_num]=useState(1)

const agent_data=()=>{
       axios.get(`${process.env.REACT_APP_BASE_URL2}/agent-data`)
    .then((res)=>{
        setpending_agent(res.data.pending_agent)
    }).catch((err)=>{
        console.log(err)
    })
}
// delete_agent
const delete_agent=(id)=>{
    const confirm_box=window.confirm("Are you sure?");
    if(confirm_box){
            axios.delete(`${process.env.REACT_APP_BASE_URL2}/agent-delete/${id}`)
    .then((res)=>{
        agent_data();
            toast.success("Agent has been deleted!");
    }).catch((err)=>{
        console.log(err)
    })
    }
}
useEffect(()=>{
 agent_data();
},[]);
const [dollar_price,setdollar_price]=useState()
const add_dollar_price=(e)=>{
  e.preventDefault();
  if(!dollar_price){
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: "Please fill up your information!",
            showConfirmButton: true,
          });   
  }   if(dollar_price){
             axios.post(`${process.env.REACT_APP_BASE_URL2}/dollar-price`,{price:dollar_price}) 
             .then((res)=>{ 
              if(res.data.success){
                     Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: res.data.message,
                        showConfirmButton: true,
               });   
              } else{
                            Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: res.data.message,
            showConfirmButton: true,
          });   
              }
             }).catch((err)=>{
              console.log(err)
             })
       }
  
}
  return (
   <>
     <Box display={isNonMobile ? "flex" : "block"} sx={{display:"flex",justifyContent:'space-between'}} width="100%" height="100%">
      <section className="w-full p-[20px]">
          <div>
            <h2 className="text-[30px] font-[600] mb-[10px]">Update Currency Price</h2>
            <p className="text-[17px] text-neutral-700">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet unde, nulla ad consectetur quam magnam.</p>
          </div>

          {/* ------------------table---------------- */}



<section className="mt-[30px]">
    
<form className="w-[30%] h-auto p-[20px] bg-white border-[1px] border-[#eee] shadow-lg" onSubmit={add_dollar_price}>
  <h1 className="text-[22px] font-[600] mb-[15px] text-center">Dollar Price</h1>
  <label htmlFor="website-admin" className="block mb-2 text-[17px] font-medium text-gray-900 dark:text-white">Price</label>
  <div className="flex">
    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
      <FiDollarSign className="text-[22px]"/>
    </span>
    <input type="text" id="website-admin"onChange={(e)=>{setdollar_price(e.target.value)}} className="rounded-none rounded-e-lg bg-white h-[50px] border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Bonnie Green" />
  </div>
<button class="text-white mt-[15px] bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
</form>


</section>

<section>
<div className="bg-white p-8 overflow-auto mt-16 h-screen">
  <h2 className="text-2xl mb-4">Price List</h2>
  {/* Classes Table */}
  <div className="relative overflow-auto">
    <div className="overflow-x-auto rounded-lg">
      <table className="min-w-full bg-white border mb-20">
        <thead>
          <tr className="bg-[#2B4DC994] text-center text-xs md:text-sm font-thin text-white">
            <th className="p-0">
              <span className="block py-2 px-3 border-r border-gray-300">ID</span>
            </th>
            <th className="p-0">
              <span className="block py-2 px-3 border-r border-gray-300">Currency Name</span>
            </th>
            <th className="p-0">
              <span className="block py-2 px-3 border-r border-gray-300">Price</span>
            </th>
            <th className="p-4 text-xs md:text-sm">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b text-xs md:text-sm text-center text-gray-800">
            <td className="p-2 md:p-4">01</td>
            <td className="p-2 md:p-4">Class 1</td>
            <td className="p-2 md:p-4">Beginner</td>
            <td className="relative p-2 md:p-4 flex justify-center space-x-2">
              <button className="bg-blue-500 text-white px-3 py-1 rounded-md text-xs md:text-sm">Edit</button>
              <button className="bg-red-500 text-white px-3 py-1 rounded-md text-xs md:text-sm">Delete</button>
            </td>
          </tr>
          <tr className="border-b text-xs md:text-sm text-center text-gray-800">
            <td className="p-2 md:p-4">02</td>
            <td className="p-2 md:p-4">Class 2</td>
            <td className="p-2 md:p-4">Intermediate</td>
            <td className="relative p-2 md:p-4 flex justify-center space-x-2">
              <button className="bg-blue-500 text-white px-3 py-1 rounded-md text-xs md:text-sm">Edit</button>
              <button className="bg-red-500 text-white px-3 py-1 rounded-md text-xs md:text-sm">Delete</button>
            </td>
          </tr>
          <tr className="border-b text-xs md:text-sm text-center text-gray-800">
            <td className="p-2 md:p-4">03</td>
            <td className="p-2 md:p-4">Class 3</td>
            <td className="p-2 md:p-4">Advanced</td>
            <td className="relative p-2 md:p-4 flex justify-center space-x-2">
              <button className="bg-blue-500 text-white px-3 py-1 rounded-md text-xs md:text-sm">Edit</button>
              <button className="bg-red-500 text-white px-3 py-1 rounded-md text-xs md:text-sm">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

</section>




          {/* ----------------------table--------------- */}
      </section>
    </Box>
   
 
   </>
  );
};

export default Moneyconvertor;
